.image {
  position: relative;
  width: fit-content;
}

.cameraIcon {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.image:hover {
  transition: 0.3s ease;
  filter: brightness(50%);

  .cameraIcon {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .galleryIcon {
    opacity: 0.2;
    transition: 0.2s all;
  }
}
