* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'Rubik', sans-serif !important;
}

input {
  font-family: 'Poppins', sans-serif !important;
}

input::-webkit-input-placeholder {
  font-family: 'Poppins', sans-serif !important;
}

input:-ms-input-placeholder {
  font-family: 'Poppins', sans-serif !important;
}

input:-moz-placeholder {
  font-family: 'Poppins', sans-serif !important;
}

input::-moz-placeholder {
  font-family: 'Poppins', sans-serif !important;
}

button {
  text-transform: none !important;
}
