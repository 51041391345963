.quill {
  .ql-toolbar {
    border-radius: 10px 10px 0 0;
    // color: 'primary.main';

    .ql-stroke {
      fill: none;
      // stroke: 'primary.main';
    }

    .ql-fill {
      // fill: 'primary.main';
      stroke: none;
    }

    // .ql-picker {
    //    color: 'primary.main';
    // }
  }

  .ql-container {
    border-radius: 0 0 10px 10px;
    height: 300px;
  }
}

.error {
  .ql-toolbar {
    border-color: #922e2e;
    color: #922e2e;

    .ql-stroke {
      stroke: #922e2e;
    }

    .ql-fill {
      fill: #922e2e;
    }

    .ql-picker {
      color: #922e2e;
    }
  }

  .ql-container {
    border-color: #922e2e;
  }
}
